import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import SlpitOne from "./SlpitOne";
import SlipTwo from "./SlipTwo";
import SlipThree from "./SlipThree";
import SlipFour from "./SlipFour";
import Separator from "../separator/Separator";
import ScrollAnimation from "react-animate-on-scroll";

const Data = [
    {
        countNum : 199,
        countTitle: 'Happy Clients.',
    },
    {
        countNum : 575,
        countTitle: 'Employees',
    },
    {
        countNum : 69,
        countTitle: 'Useful Programs',
    },
    {
        countNum : 500,
        countTitle: 'Useful Programs',
    },
];
const Split = () => {
    return (
        <>

                <div className="main-content">
                    {/* First*/}
                    <div className="rwt-split-area rn-section-gap">
                        <div className="wrapper">
                        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/managerPanel.png" alt="split Images" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="title">Manager Panel</h4>
                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">Vista’s Management Panel provides a comprehensive solution for handling all key aspects of your operations, including reservation management, seamless communication with guests, efficient personnel management, and ensuring high levels of guest satisfaction. By consolidating these functions into a single, user-friendly platform, it allows you to streamline your daily tasks, reduce manual effort, and maintain greater control over your business. With this centralized system, you can improve the overall efficiency of your operations, minimize potential errors, and focus more on delivering exceptional experiences to your guests.</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>

        </div>
                        </div>
                    </div>
                     {/* Second*/}
                    <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="title">Owner Panel</h4>
                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">This tool offers real-time insights into your business’s financial performance by giving you up-to-the-minute information on both income and expenses. It goes beyond simply presenting raw data; it serves as a strategic guide that empowers you to make smarter and faster business decisions. By analyzing live financial data, you can instantly adjust and refine your business strategies to respond to changing circumstances or market trends. This level of real-time analysis enables you to spot opportunities for growth, reduce inefficiencies, and make proactive changes to enhance your overall business operations. As you utilize these insights, you'll see your financial health improve, giving you the confidence to navigate the complexities of your business with greater clarity and control. In essence, it’s more than a tool—it’s your dynamic roadmap to a more profitable and resilient business future.</p>
                            </ScrollAnimation>

                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/ownerPanel.png" alt="split Images" />
                        </div>
                    </div>
                </div>
            </div>
                     </div>
                      {/* Third*/}
                     <div className="rwt-split-area rn-section-gap">
                        <div className="wrapper">
                        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/guestPanel.png" alt="split Images" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="title">Guest Panel</h4>
                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">Our Summit Smart sensors, integrated with Alexa and Google Home, offer guests an effortless way to manage their environment and services. With just a voice command, you can adjust room lighting and temperature, order room service, or book local experiences—all through the convenience of your WiFi-connected devices. This service isn't just about making things easier; it’s about creating a seamless, luxurious experience where cutting-edge technology enhances every moment of your stay.</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>

        </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

                </div>

        </>
    )
}
export default Split;