import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import PrivacyPage from '../elements/PrivacyPage';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Slider from '../elements/slider/slider';
import GoogleMap from "../elements/about/GoogleMap";
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';

const Privacy = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Elements Area  */}    
                <div>
                    <div className="container" id='smart-apt'>
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Solutions"
                                    title = "Smart Hotels We Manage"
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <PrivacyPage />    
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}


                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Privacy;
