import React from 'react';
import Typed from 'react-typed';
const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                   
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                            <h2 className="title " style={{textAlign:"center"}}> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Istanbul",
                                            "SmartApt",
                                            "Ortakoy",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Smartapt is located below Portakal Yokusu in a tranquil neighborhood between Ulus and Ortakoy. The area is one of the few authentic places in the city which still preserves the elegant architectural character of the Old Istanbul Bosphorus village.</p>
                                <p>Smartapt is situated within 10 minutes walking distance to the main Bosphorus road and Ortakoy Square. The location is just perfect. A great choice of excellent restaurants and bars are very nearby. It is only a short walk to public transport and it is easy to reach key sights.</p>
                                <p>The modern financial district Zincirlikuyu-Levent-Maslak is only 10-15 minutes driving distance. Trendy shopping districts like Nisantasi and malls like Zorlu Center are just 15 minutes away by taxi/car.</p>
                                <p>The world-famous nightclubs Reina, Sortie, and Suada are also located in Ortakoy.</p>
                                <p>Our apartment has 7 units and 4 floors. There is a terrace located on the rooftop with a fantastic view of the Bosphorus and the Old City for common use. There is also a business center and a meeting room situated in the basement for optional use.</p>
                                <p>Every unit has a private bathroom with a shower, a fully equipped kitchen, a living area with a sofa, a dining table and chairs, and one or two bedrooms.</p>
                                <p>All the apartments have free Wi-Fi connection and TV.</p>


                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about1.png" alt="About Images" />
                        </div>
                    </div>

                </div>
            </div>   
        </div>
    )
}

export default AboutOne