import React from 'react';
import Typed from 'react-typed';
const PrivacyPage = () => {
return (
    <div className="rwt-about-area rn-section-gap">
        <div className="container">
            <div className="row row--30 align-items-center">
                <div className="col-lg-12 mt_md--40 mt_sm--40 p-50">
                    <div className="content">
                        <div className="section-title">
                        <h2 className="title " style={{textAlign:"center"}}> {" "}
                            <Typed className="theme-gradient"
                                strings={[
                                    "PRIVACY AND SECURITY POLICY",                                    
                                ]}
                                typeSpeed={80}
                                backSpeed={5}
                                backDelay={1000}
                                loop
                            />
                        </h2>
                        <p> All services provided in our store and the company Summit Technology Ltd. registered at the address Istanbul/Turkey belong to our company and are operated by our company. 
                            Our company may collect personal data for various purposes. Below is stated how and in what ways the personal data collected is collected and how it is protected. 
                            By filling out various forms and surveys on our store, certain personal information (such as name-surname, company details, phone number, address, or e-mail addresses) related to the members is collected by our store as required by the nature of the business. 
                            Our company may send campaign information, information about new products, or promotional offers to its customers and members during certain periods. Our members can make all kinds of choices about whether to receive such information while becoming a member, and later this selection can be changed from the account information section after logging in or by clicking the link in the notification they receive. 
                            During the approval process conducted through our store or via email, personal information transmitted electronically to our store by our members will not be disclosed to third parties outside the purposes and scope specified by the&quot;User Agreemen&quot; we have made with our members.
                            To identify system-related issues and to swiftly resolve any problems or disputes that may arise related to the services provided, our company records and uses the IP address of its members. IP addresses may also be used to generally identify users and to collect comprehensive demographic information.
                            Our company can also use the information requested for purposes beyond those set out by the Membership Agreement to conduct direct marketing by itself or through its collaborators. Personal information may also be used when necessary to contact the user. Information requested by our company or information provided by the user or information regarding transactions carried out through our store may be used for various statistical evaluations, database creation, and market research without disclosing the identities of our members outside the purposes and scope specified by the &quot;Membership Agreement&quot; by our company and its collaborators.
                            Our company commits to keeping confidential information strictly private and confidential, regarding it as a duty to maintain secrecy and to take all necessary measures to ensure and maintain confidentiality and to prevent any part or all of the confidential information from entering the public domain or being disclosed to unauthorized use or to third parties, and to show the necessary diligence.
                        </p>
                        <h6>CREDIT CARD SECURITY</h6>
                        <p> Our company prioritizes the security of credit cardholders shopping on our shopping sites. Your credit card information is never stored in our system.
                            When you enter the transaction process, there are two things you need to pay attention to to understand that you are on a secure site. One of them is the key or lock symbol at the bottom line of your browser. This indicates that you are on a secure web page and your information is protected by encryption. This information is only used in the direction of the instructions you give and in relation to the sales transactions. The information related to the credit card used during shopping is encrypted with the 128-bit SSL (Secure Sockets Layer) protocol independently from our shopping sites and sent to the relevant bank for questioning. If the card is approved for use, shopping continues. Since no information about the card can be viewed or recorded by us, it is prevented for third parties to seize this information under any circumstances.
                            The reliability of the payment/invoice/delivery address information of online orders placed with a credit card is checked by our company against Credit Card Fraud. Therefore, in order for first-time customers to reach the supply and delivery stage, the accuracy of financial and address/phone information must first be confirmed. If necessary, the credit card owner customer or the relevant bank may be contacted for information control.
                            All the information you provide when becoming a member can only be accessed and changed by you. As long as you securely protect your member login details, it is impossible for others to access and change information about you. For this purpose, during membership processes, action is taken within a 128-bit SSL security area. This system is an international encryption standard that cannot be broken.
                            Nowadays, Internet shopping sites with information hotlines or customer service lines and open address and phone information are more preferred. In this way, you can obtain detailed information on all topics that concern you, and you can get healthier information about the reliability of the company providing online shopping services. 
                            Note: We recommend that attention is paid to the open address and phone number of the company in Internet shopping sites. If you are going to shop, note down all the phone/address information of the store where you purchased the product. If you do not trust the company, verify by calling before shopping. All the information about our company and the location of the company is indicated on all our online shopping sites.
                        </p>
                        <h6>MAIL ORDER CREDIT CARD SECURITY</h6>
                        <p>Your identity and credit card information that you send to us by the mail-order method will be stored by our company in accordance with the privacy principle. This information is kept for 60 days in case of a possible objection to the withdrawal of money from the credit card with the bank and then destroyed. Since you can naturally object to the bank and prevent the payment of the amount in case any charge other than the approved mail-order form amount sent to us for the price of the products you ordered is charged to your card, it does not pose a risk.                                </p>
                               
                        <h6>THIRD-PARTY WEBSITES AND APPLICATIONS</h6>
                        <p>Our store may provide links to other sites within the website. Our company bears no responsibility for the privacy practices and content of the sites accessed via these links. The advertisements published on our website are distributed to our users via our advertising partners. The Privacy Policy Principles in this agreement are only related to the use of our store, they do not cover third-party websites.</p>

                        <h6>EXCEPTIONAL CASES</h6>
                        <p>In limited cases specified below, our company may disclose users&apos; information to third parties outside the provisions of this &quot;Privacy Policy.&quot; These situations are limited in number;
                        <ul>
                            <li><span>01-</span>To comply with the obligations brought by the legal rules in force such as laws, decree-laws, regulations, etc. issued by the competent legal authority</li>
                            <li><span>02-</span>To fulfill the requirements of the &quot;Membership Agreement&quot; and other agreements concluded by our store with users and to put these into practice</li>
                            <li><span>03-</span>To respond to an investigation or inquiry properly conducted by the competent administrative and judicial authority with a request for information about users</li>
                            <li><span>04-</span>Cases where information needs to be provided to protect users&apos; rights or security</li>
                         </ul>
                        </p>
                        <h6>EMAIL SECURITY</h6>
                        <p>Never write your credit card number or passwords in the emails you send to our Customer Service regarding any of your orders. The information contained in emails can be seen by third parties. Our company cannot guarantee the security of the information transmitted from your emails under any circumstances.
                        </p>
                        <h6>BROWSER COOKIES</h6>
                        <p> Our company can obtain information about users visiting our store and the usage of the website through a technical communication file (Cookie). The mentioned technical communication files are small text files sent by an Internet site to the browser of the user to be stored in the main memory. The technical communication file facilitates Internet use by storing status and preferences about the site.
                        The technical communication file helps to obtain statistical information about how many people visited the site, for what purpose, how many times a person visited the site, and how long they stayed, and to generate dynamically tailored ads and content for users on specially designed user pages. The technical communication file is not designed to retrieve data or any other personal information from the user&apos;s main memory or email. Many browsers are initially designed to accept the technical communication file, but users can change their settings to prevent the technical communication file from being received or to ensure that they are warned when the technical communication file is sent.
                        </p>
                        <p>Our company can change the provisions of this &quot;Privacy Policy&quot; at any time by publishing it on the site or sending an email to the users or publishing it on the site. The provisions of the Privacy Policy come into force on the date they are published.
                        For any questions and suggestions regarding our privacy policy, you can send an email to info@sumtech.io. You can reach our company through the contact details below.
                        <br /><br/>Company Name: Summit Tech <br />
                        Address: Beşiktaş/Istanbul <br /> 
                        Email:  info@sumtech.io <br />
                        Tel: (+90) 539 616 2734  <br />                       
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>
 )
}

export default PrivacyPage
